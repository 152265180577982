import { environment } from "src/environments/environment";

export const API_END_POINTS = {
  AUTH: {
    login: `${environment.APIBaseUrl}/${environment.routePrefix}/auth/login`
  },
  SETUP: {
    setup_status: `${environment.APIBaseUrl}/${environment.routePrefix}/static/setup/status`,
    assets: `${environment.APIBaseUrl}/${environment.routePrefix}/asset`,
    parameters: `${environment.APIBaseUrl}/${environment.routePrefix}/user/parameter`,
    parameter_create: `${environment.APIBaseUrl}/${environment.routePrefix}/user/parameter/create`,
    parameter_update: `${environment.APIBaseUrl}/${environment.routePrefix}/user/parameter/update`,
    parameter_status: `${environment.APIBaseUrl}/${environment.routePrefix}/user/parameter/status`,
    paramater_validate: `${environment.APIBaseUrl}/${environment.routePrefix}/user/parameter/validate-criteria`,
    parameter_weight: `${environment.APIBaseUrl}/${environment.routePrefix}/user/parameter/weight`,
    multiple_parameter_weight: `${environment.APIBaseUrl}/${environment.routePrefix}/user/parameter/multiple-weight`,
    selected_assets: `${environment.APIBaseUrl}/${environment.routePrefix}/user/selectedAsset`,
    create_universe: `${environment.APIBaseUrl}/${environment.routePrefix}/universe/stock/create`,
    search_universe: `${environment.APIBaseUrl}/${environment.routePrefix}/universe/universe/search`,
    green_rational_upload: `${environment.APIBaseUrl}/${environment.routePrefix}/document/upload/green/rational`,
    red_rational_upload: `${environment.APIBaseUrl}/${environment.routePrefix}/document/upload/red/rational`,
    green_rational_delete: `${environment.APIBaseUrl}/${environment.routePrefix}/document/delete/greenUniverse`,
    red_rational_delete: `${environment.APIBaseUrl}/${environment.routePrefix}/document/delete/redUniverse`,
    green_rational: `${environment.APIBaseUrl}/${environment.routePrefix}/document/download/greenUniverse`,
    red_rational: `${environment.APIBaseUrl}/${environment.routePrefix}/document/download/reduniverse`,
    tail_redemption: `${environment.APIBaseUrl}/${environment.routePrefix}/static`,
    get_preset: `${environment.APIBaseUrl}/${environment.routePrefix}/preset/config`,
    add_preset: `${environment.APIBaseUrl}/${environment.routePrefix}/preset/config/add`,
    update_preset: `${environment.APIBaseUrl}/${environment.routePrefix}/preset/config/update`,
    delete_preset: `${environment.APIBaseUrl}/${environment.routePrefix}/preset/config/delete`,
    tailconfig: `${environment.APIBaseUrl}/${environment.routePrefix}/tailValue/config`,
    update_tailconfig: `${environment.APIBaseUrl}/${environment.routePrefix}/tailValue/config/update`,
    delete_tailconfig: `${environment.APIBaseUrl}/${environment.routePrefix}/tailValue/config/delete`,
    tailconfiggroup: `${environment.APIBaseUrl}/${environment.routePrefix}/tailValue/config/group`,
    update_tailconfiggroup: `${environment.APIBaseUrl}/${environment.routePrefix}/tailValue/config/group/update`,
    add_tailconfiggroup: `${environment.APIBaseUrl}/${environment.routePrefix}/tailValue/config/addGroup`,
    delete_tailconfiggroup: `${environment.APIBaseUrl}/${environment.routePrefix}/tailValue/config/group/delete`,
    tailValue: `${environment.APIBaseUrl}/${environment.routePrefix}/tailValue/config/update`,
    config_status: `${environment.APIBaseUrl}/${environment.routePrefix}/static/configGroup/status`,
    PUBLISH_MASTER: {
      publish_master: `${environment.APIBaseUrl}/${environment.routePrefix}/PublishedUniverseMaster/groupInfo`,
      add_publish_master: `${environment.APIBaseUrl}/${environment.routePrefix}/PublishedUniverseMaster/add`,
      delete_publish_master: `${environment.APIBaseUrl}/${environment.routePrefix}/PublishedUniverseMaster/delete`,
      upload_publish_master: `${environment.APIBaseUrl}/${environment.routePrefix}/PublishedUniverseMaster/upload/excel`,
      instrument_publish_master: `${environment.APIBaseUrl}/${environment.routePrefix}/PublishedUniverseMaster/instruments`,
      download_publish_master: `${environment.APIBaseUrl}/${environment.routePrefix}/PublishedUniverseMaster/download/excel`,
      upload_rational: `${environment.APIBaseUrl}/${environment.routePrefix}/document/upload/published/rational`,
      download_rational: `${environment.APIBaseUrl}/${environment.routePrefix}/document/download/published/rational`,
      delete_rational: `${environment.APIBaseUrl}/${environment.routePrefix}/document/delete/published/rational`,
      update_rational: `${environment.APIBaseUrl}/${environment.routePrefix}/PublishedUniverseMaster/updateRational`
    },
    GREEN: {
      add_investment_universe: `${environment.APIBaseUrl}/${environment.routePrefix}/green/config/create`,
      investment_universe: `${environment.APIBaseUrl}/${environment.routePrefix}/green/config`,
      edit_investment_universe: `${environment.APIBaseUrl}/${environment.routePrefix}/green/config/update`,
      green_universe: `${environment.APIBaseUrl}/${environment.routePrefix}/green`,
      upload_excel: `${environment.APIBaseUrl}/${environment.routePrefix}/document/green/upload/excel`,
      groupinfo: `${environment.APIBaseUrl}/${environment.routePrefix}/green/groupInfo`,
      peers: `${environment.APIBaseUrl}/${environment.routePrefix}/peersUniverse`,
      download_excel: `${environment.APIBaseUrl}/${environment.routePrefix}/document/greenUniverse/download/excel`,
      priority: `${environment.APIBaseUrl}/${environment.routePrefix}/green/update/priority`
    },
    PRESET: {
      config_preset: `${environment.APIBaseUrl}/${environment.routePrefix}/ConfigCriteria`,
      config_preset_status: `${environment.APIBaseUrl}/${environment.routePrefix}/ConfigCriteria/status`,
      config_preset_weight: `${environment.APIBaseUrl}/${environment.routePrefix}/Config/parameters/distribution/weights`,
      update_config_preset_weight: `${environment.APIBaseUrl}/${environment.routePrefix}/Config/parameters/distribution/multiple-weight`,
      selected_asset: `${environment.APIBaseUrl}/${environment.routePrefix}/user/selectedAsset`,
      criteria: `${environment.APIBaseUrl}/${environment.routePrefix}/distribution`,
      update_criteria: `${environment.APIBaseUrl}/${environment.routePrefix}/distribution/updateValues`
    },
    RED: {
      add_redemption_universe: `${environment.APIBaseUrl}/${environment.routePrefix}/red/config/create`,
      redemption_universe: `${environment.APIBaseUrl}/${environment.routePrefix}/red/config`,
      edit_redemption_universe: `${environment.APIBaseUrl}/${environment.routePrefix}/red/config/update`,
      red_universe: `${environment.APIBaseUrl}/${environment.routePrefix}/red`,
      groupinfo: `${environment.APIBaseUrl}/${environment.routePrefix}/red/groupInfo`,
      upload_excel: `${environment.APIBaseUrl}/${environment.routePrefix}/document/admin/red/upload/excel`,
      download_excel: `${environment.APIBaseUrl}/${environment.routePrefix}/document/redUniverse/download/excel`
    },
    REDEMPTION_POOL: {
      create: `${environment.APIBaseUrl}/${environment.routePrefix}/redemptionPool/create`,
      update: `${environment.APIBaseUrl}/${environment.routePrefix}/redemptionPool/update`,
      data: `${environment.APIBaseUrl}/${environment.routePrefix}/redemptionPool`,
      byId: `${environment.APIBaseUrl}/${environment.routePrefix}/redemptionPool/byId`,
      assets: `${environment.APIBaseUrl}/${environment.routePrefix}/asset/withGrandChild`,
      mapping: `${environment.APIBaseUrl}/${environment.routePrefix}/redemption/pool/mapping`
    },
    CONCENTRATE: {
      create: `${environment.APIBaseUrl}/${environment.routePrefix}/concentrate/redemption/create`,
      update: `${environment.APIBaseUrl}/${environment.routePrefix}/concentrate/redemption/update`,
      data: `${environment.APIBaseUrl}/${environment.routePrefix}/concentrate/redemption`
    },
    MASTER_CONTROL: {
      list: `${environment.APIBaseUrl}/${environment.routePrefix}/master/control/list`,
      sector_list: `${environment.APIBaseUrl}/${environment.routePrefix}/master/control/sector/list`,
      create: `${environment.APIBaseUrl}/${environment.routePrefix}/master/control/create/list`,
      update: `${environment.APIBaseUrl}/${environment.routePrefix}/master/control/update/list`,
      upload_excel: `${environment.APIBaseUrl}/${environment.routePrefix}/master/control/update/list/excel`,
      byID: `${environment.APIBaseUrl}/${environment.routePrefix}/master/control/byId`,
      download_master: `${environment.APIBaseUrl}/${environment.routePrefix}/master/control/download/excel`,
      ignored_list: `${environment.APIBaseUrl}/${environment.routePrefix}/master/control/ignored/list/download/excel`,
      upload_onepage_image: `${environment.APIBaseUrl}/${environment.routePrefix}/PublishedUniverseMaster/upload/onePager/image`,
      get_onepager_image: `${environment.APIBaseUrl}/${environment.routePrefix}/PublishedUniverseMaster/get/onePager/image`,
      delete_onepager_image: `${environment.APIBaseUrl}/${environment.routePrefix}/PublishedUniverseMaster/delete/onePager/image`
    }
  },
}